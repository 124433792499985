import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import "hammerjs";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr";

import { CoreModule } from "@core/core.module";
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule, CoreThemeCustomizerModule } from "@core/components";

import { coreConfig } from "app/app-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { ErrorComponent } from "./main/pages/miscellaneous/error/error.component";
import { ErrorInterceptor, JwtInterceptor } from "@core/helpers";
import { LandingPageComponent } from "./main/pages/landing-page/landing-page.component";
import { UpgradePageComponent } from "./main/pages/payment-page/upgrade-page.component";
import { CookieModule } from "ngx-cookie";
import { OnboardingComponent } from "./main/pages/onboarding/onboarding.component";
import { AdRedirectingPageComponent } from "./main/pages/ad-redirecting-page/ad-redirecting-page.component";

const appRoutes: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("./main/dashboards/dashboards.module").then(
        (m) => m.DashboardsModule
      ),
  },
  {
    path: "learn",
    loadChildren: () =>
      import("./main/learn/learn.module").then((m) => m.LearnModule),
  },
  {
    path: "help",
    loadChildren: () =>
      import("./main/help/help.module").then((m) => m.HelpModule),
  },
  {
    path: "notes",
    loadChildren: () =>
      import("./main/notes/notes.module").then((m) => m.NotesModule),
  },
  {
    path: "assignments",
    loadChildren: () =>
      import("./main/assignments/assignments.module").then(
        (m) => m.AssignmentsModule
      ),
  },
  {
    path: "quiz",
    loadChildren: () =>
      import("./main/quiz/quiz.module").then((m) => m.QuizModule),
  },
  {
    path: "review-quiz",
    loadChildren: () =>
      import(
        "./main/quiz/components/review-quizzes/review-quizzes.module"
      ).then((m) => m.ReviewQuizzesModule),
  },
  {
    path: "review-questions",
    loadChildren: () =>
      import("./main/quiz/components/new-question/new-question.module").then(
        (m) => m.NewQuestionModule
      ),
  },
  {
    path: "exam-practice",
    loadChildren: () =>
      import("./main/quiz/components/exam-practice/exam-practice.module").then(
        (m) => m.ExamPracticeModule
      ),
  },
  {
    path: "exams",
    loadChildren: () =>
      import("./main/exams/exams.module").then((m) => m.ExamsModule),
  },
  {
    path: "school",
    loadChildren: () =>
      import("./main/school/school.module").then((m) => m.MySchoolModule),
  },
  {
    path: "schools",
    loadChildren: () =>
      import("./main/schools/schools.module").then((m) => m.SchoolsModule),
  },
  {
    path: "upload",
    loadChildren: () =>
      import("./main/upload-content/upload-content.module").then(
        (m) => m.UploadContentModule
      ),
  },
  {
    path: "account-settings",
    loadChildren: () =>
      import("./main/account-settings/account-settings.module").then(
        (m) => m.AccountSettingsModule
      ),
  },
  {
    path: "pages",
    loadChildren: () =>
      import("./main/pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./main/pages/profile/profile.module").then(
        (m) => m.ProfileModule
      ),
  },
  {
    path: "statistics",
    loadChildren: () =>
      import("./main/statistics/statistics.module").then(
        (m) => m.StatisticsModule
      ),
  },
  {
    path: "subscribed-users",
    loadChildren: () =>
      import(
        "./main/support-subscribed-users/support-subscribed-users.module"
      ).then((m) => m.SupportSubscribedUsersModule),
  },
  {
    path: "login",
    component: LandingPageComponent,
  },
  {
    path: "onboarding",
    component: OnboardingComponent,
  },
  {
    path: "logout",
    component: LandingPageComponent,
  },
  {
    path: "upgrade",
    component: UpgradePageComponent,
  },
  {
    path: "messages",
    component: AdRedirectingPageComponent,
  },
  {
    path: "pages/miscellaneous/error",
    component: ErrorComponent,
  },
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "/pages/miscellaneous/error", //Error 404 - Page not found
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "enabled", // Add options right here
      relativeLinkResolution: "legacy",
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    CookieModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
})
export class AppModule {}
