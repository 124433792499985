import { CoreMenu, Role } from "@core/models";
import { type } from "os";

export const menu: CoreMenu[] = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: "home",
    url: "dashboard",
  },
  {
    id: "learn",
    title: "Learn",
    type: "item",
    icon: "play-circle",
    url: "learn",
    role: [
      Role.SuperAdmin,
      Role.Admin,
      Role.Teacher,
      Role.CustomerSupportAdmin,
    ],
  },
  {
    id: "notes",
    title: "Notes",
    type: "item",
    customIcon: "notes",
    url: "notes",
    role: [Role.Student, Role.Teacher, Role.Admin],
  },
  {
    id: "assignments",
    title: "Assignments",
    type: "item",
    customIcon: "assignments",
    url: "assignments",
    role: [Role.Student, Role.independentStudent, Role.Teacher, Role.Admin],
  },
  {
    id: "quizzes",
    title: "New Qustions",
    type: "item",
    customIcon: "quiz",
    url: "quiz",
    role: [
      Role.Teacher,
      Role.Admin,
      Role.SuperAdmin,
    ],
  },
  {
    id: 'review',
    title: 'Review Old',
    type: 'item',
    icon: 'edit',
    url: 'review-quiz',
    role:[Role.Teacher,Role.Admin,Role.SuperAdmin]
  },
  {
    id: 'new-questions',
    title: 'Review New',
    type: 'item',
    icon: 'folder-plus',
    url: 'review-questions/new',
    role:[Role.Teacher,Role.Admin,Role.SuperAdmin]
  },
  {
    id: "exams",
    title: "Examinations",
    type: "item",
    customIcon: "examinations",
    url: "exams",
  },
  {
    id: "practicals",
    title: "KCSE Practicals",
    type: "item",
    icon: "pen-tool",
    url: "learn/practical-subject-selection",
    role: [Role.Student, Role.independentStudent],
  },
  {
    id: "school",
    title: "My school",
    type: "item",
    customIcon: "school",
    url: "school",
    role: [Role.Admin],
  },
  {
    id: "schools",
    title: "Schools",
    type: "item",
    icon: "briefcase",
    url: "schools",
    role: [Role.SuperAdmin, Role.CustomerSupportAdmin],
  },
  {
    id: "upload",
    title: "Upload",
    type: "item",
    icon: "upload",
    url: "upload",
    role: [Role.SuperAdmin],
  },
  {
    id: "support-subscribed-users",
    title: "Attempted Subs",
    type: "item",
    icon: "users",
    url: "subscribed-users",
    role: [Role.CustomerSupportAdmin],
  },
  {
    id: "statistics",
    title: "Statistics",
    type: "item",
    icon: "bar-chart-2",
    url: "statistics/student-statistics",
    role: [Role.Student, Role.independentStudent],
  },
  {
    id: "help",
    title: "Help",
    type: "item",
    icon: "help-circle",
    url: "help",
  },
  {
    id: "logout",
    title: "Log Out",
    type: "item",
    icon: "log-out",
    url: "logout",
  },
];
