import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { QuizAccess } from "@core/models/quiz-access";
import { Params } from "@angular/router";
import { TagDetails } from "@core/models/lesson-access";
import { NewQuiz } from "app/main/quiz/interfaces/new-quiz";
import { PAGE_PARAMS } from "@core/constants/consts";
import { ExamPracticeQuestion } from "@core/models";

@Injectable({ providedIn: "root" })
export class QuizService {
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {}

  /**
   * Get quiz by form and subject
   */
  getQuizByForm(subjectId: number, classLevel: number): Observable<any[]> {
    return this._http.get<any[]>(
      `${environment.apiUrl}/quiz?topicId=&subjectId=${subjectId}&classLevel=${classLevel}`
    );
  }

  /**
   * Get quiz by topic
   */
  getQuizByTopic(topicId: number): Observable<any[]> {
    return this._http.get<any[]>(
      `${environment.apiUrl}/quiz?topicId=${topicId}`
    );
  }

  /**
   * Log quiz attempe
   */
  logQuizAttempt(correct: boolean, questionId: number): Observable<any[]> {
    return this._http.post<any[]>(
      `${environment.apiUrl}/quiz/results?gotCorrect=${
        correct ? "true" : "false"
      }&questionId=${questionId}`,
      {}
    );
  }

  getQuizPerformance(
    form: number,
    subjectId: number
  ): Observable<QuizAccess[]> {
    return this._http.get<QuizAccess[]>(
      environment.apiUrl + `/quiz/report?subjectId=${subjectId}&form=${form}`
    );
  }

  getReviewQuezes(
    page: number,
    pageSize: number,
    subjectId: number,
    form: number,
    topicId?: number
  ): Observable<any> {
    let url = topicId
      ? `${environment.apiUrl}/quiz/all/?page=${page}&pageSize=${pageSize}&subjectId=${subjectId}&form=${form}&topicId=${topicId}`
      : `${environment.apiUrl}/quiz/all/?page=${page}&pageSize=${pageSize}&subjectId=${subjectId}&form=${form}`;
    return this._http.get<any>(url);
  }

  getExamPracticeNewQuestions(
    subjectId: number,
    topicId: number
  ): Observable<unknown> {
    let url = topicId
      ? `${environment.apiUrl}/exam-practise?subjectId=${subjectId}&topicId=${topicId}`
      : `${environment.apiUrl}/exam-practise?subjectId=${subjectId}`;
    return this._http.get<any>(url);
  }

  getNewQuestions(
    params: Params,
    subjectId: number,
    form: number,
    topicId?: number
  ): Observable<unknown> {
    params = { ...PAGE_PARAMS, ...params };
    let url = topicId
      ? `${environment.apiUrl}/quiz/all/added?subjectId=${subjectId}&form=${form}&topicId=${topicId}`
      : `${environment.apiUrl}/quiz/all/added?subjectId=${subjectId}&form=${form}`;
    return this._http.get<any>(url, { params });
  }

  confirmAddedQuestion(questionId: number): Observable<any> {
    return this._http.post<any>(
      `${environment.apiUrl}/quiz-review/update?questionId=${questionId}&status=true`,
      null
    );
  }

  confirmQuestion(questionId: number): Observable<any> {
    return this._http.post<any>(
      `${environment.apiUrl}/quiz/update?questionId=${questionId}&status=true`,
      null
    );
  }

  declineQuestion(questionId: number): Observable<any> {
    return this._http.post<any>(
      `${environment.apiUrl}/quiz/update?questionId=${questionId}&status=false`,
      null
    );
  }

  editQuestion(questionId: number, params: Params): Observable<any> {
    return this._http.post<any>(
      `${environment.apiUrl}/quiz/update/question?questionId=${questionId}`,
      params
    );
  }

  editAnswer(
    answerId: number,
    isCorrect: boolean,
    params: Params
  ): Observable<any> {
    return this._http.post<any>(
      `${environment.apiUrl}/quiz/update/answer?answerId=${answerId}&isCorrect=${isCorrect}`,
      params
    );
  }

  tagQuestion(tagDetails: TagDetails): Observable<any> {
    return this._http.put<any>(`${environment.apiUrl}/quiz-review`, tagDetails);
  }

  untagQuestion(questionId: number, lessonId: number): Observable<any> {
    return this._http.put<any>(
      `${environment.apiUrl}/quiz-review/unTag?questionId=${questionId}&lessonId=${lessonId}`,
      {}
    );
  }

  addQuiz(quizDetails: any): Observable<any> {
    return this._http.post<any>(
      `${environment.apiUrl}//extra/quiz`,
      quizDetails
    );
  }

  addExamPracticeQuestion(
    questionDetails: ExamPracticeQuestion
  ): Observable<ExamPracticeQuestion> {
    return this._http.post<any>(
      `${environment.apiUrl}/exam-practise`,
      questionDetails
    );
  }

  confirmExamPracticeQuestion(questionId: number): Observable<any> {
    return this._http.post<any>(
      `${environment.apiUrl}/exam-practise/${questionId}/review`,
      { status : "APPROVED" }
    );
  }

  editExamPractiseQuestion(questionId: number, params: Params): Observable<any> {
    return this._http.put<any>(
      `${environment.apiUrl}/exam-practise/${questionId}`,
      params
    );
  }
}
